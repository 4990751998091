import React, { useEffect, useState } from "react";
import { Images } from "../../../assets";
import SettingModal from "../settingModal/settingModal";
import { Container, Image, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { config } from "../../../config/config";
import { useTranslation } from "react-i18next";
import Sidebar from "./sidebar";
import { useLocation } from "react-router-dom";
import "./header.css";
function Header({
  increaseFontSize,
  resetFontSize,
  decreaseFontSize,
  theme,
  darkTheme,
  lightTheme,
  toggleColorlessMode,
  handleGoldenTheme,
  handleGreenTheme,
  handlePrimaryTheme,
  handleResetSetting,
}) {
  const [show, setShow] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { i18n } = useTranslation();
  const [currentLanguageIndex, setCurrentLanguageIndex] = useState(0);

  const handleTranslate = () => {
    const nextLanguageIndex = (currentLanguageIndex + 1) % config.lngs.length;
    const nextLanguageCode = config.lngs[nextLanguageIndex].code;
    i18n.changeLanguage(nextLanguageCode);
    setCurrentLanguageIndex(nextLanguageIndex);
  };

  const location = useLocation();
  const isFaqsPage = location.pathname === "/Faqs";

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header
      className={`header postion-relative ${isScrolled || isFaqsPage ? "add-bg" : ""}`}
    >
      <Container className="custom-container">
        <div className="d-flex py-2">
          <Navbar expand="lg" className="flex-fill">
            <Navbar.Brand as={Link} to="/" className="p-0">
              <Image src={Images.headerLogo} alt="logo" fluid />
            </Navbar.Brand>
            <Navbar.Collapse
              id="responsive-navbar-nav"
              className="d-lg-block d-none"
            >
              <Nav className="ms-auto my-2 my-lg-0 align-items-center">
                <Nav.Link as={Link} to="/home">
                  Home
                </Nav.Link>
                <NavDropdown title="e-Services" className="e-services">
                  <NavDropdown.Item as={Link} to="/membership">
                    Memberships
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/arbitration"
                  >
                    Arbitration Request Form
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/claim"
                  >
                    Claim Statement Form
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action4">
                    Request The Arbitrator’s Response
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/intervention"
                  >
                    Intervention Request Form
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/interpretation"
                  >
                    Interpretation Request Form
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/joinder"
                  >
                    Joinder Request Form
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action4">
                    Objection Request
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/dashboard/e-services/request/copy"
                  >
                    Request A True Copy Of The Ruling
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action3">
                    Request A Statement/Certificate
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action4">
                    Request To Select An Arbitrator Outside The Center’s List Of
                    Accredited Arbitrators
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="#action5">
                    An Arbitration Request That Is Not Subject To The Centre’s
                    Rules And Regulations
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="About Us">
                  <NavDropdown.Item as={Link} to="/about-us">
                    About Us
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/jury-panel">
                    Jury Panel
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/about-laws">
                    The Laws
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Services">
                  <NavDropdown.Item as={Link} to="/services">
                    Services
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/arbitration">
                    Arbitration
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/mediation">
                    Mediation
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/departments-Of-Center">
                    Departments Of The Center
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/center-tables">
                    Center Tables
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/media-center">
                  Media Center
                </Nav.Link>
                <Nav.Link as={Link} to="/contact-us">
                  Contact us
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Nav className="flex-row gap-3 me-3">
              <Nav.Item role="button" onClick={handleShow}>
                <Image src={Images.settingIcon} alt="icon" fluid />
              </Nav.Item>
              <Nav.Item
                role="button"
                onClick={(event) => handleTranslate(event)}
              >
                <Image
                  className="ar-lang"
                  src={Images.arLangIcon}
                  alt="icon"
                  fluid
                />
                <Image
                  className="en-lang"
                  src={Images.enLangIcon}
                  alt="icon"
                  fluid
                />
              </Nav.Item>
              <Nav.Item role="button">
                <NavLink to="/login">
                  <Image src={Images.userIcon} alt="icon" fluid />
                </NavLink>
              </Nav.Item>
            </Nav>
            <Sidebar />
          </Navbar>
        </div>
      </Container>
      <SettingModal
        increaseFontSize={increaseFontSize}
        resetFontSize={resetFontSize}
        decreaseFontSize={decreaseFontSize}
        theme={theme}
        darkTheme={darkTheme}
        lightTheme={lightTheme}
        toggleColorlessMode={toggleColorlessMode}
        show={show}
        handleClose={handleClose}
        handleGoldenTheme={handleGoldenTheme}
        handleGreenTheme={handleGreenTheme}
        handlePrimaryTheme={handlePrimaryTheme}
        handleResetSetting={handleResetSetting}
      />
    </header>
  );
}

export default Header;
