import {
  SET_LAW_PAGE_DATA,
  SET_LAW_TYPES_DATA,
  LAW_LOADER,
  DOWNLOAD_COUNT,
} from "../actionTypes";

const initialState = {
  loader: false,
  lawPageData: {},
  laws: [],
};

const lawReducer = (state = initialState, action) => {
  switch (action.type) {
    case LAW_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_LAW_TYPES_DATA: {
      return {
        ...state,
        laws: action.payload,
      };
    }
    case SET_LAW_PAGE_DATA: {
      return {
        ...state,
        lawPageData: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        lawPageData: {
          ...state.lawPageData,
          downloadCount: (state.lawPageData?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default lawReducer;
