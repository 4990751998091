import {
  SERVICE_LOADER,
  SET_SERVICES_DATA,
  SET_SINGLE_SERVICE_DATA,
  SET_SERVICES_PAGE_DATA,
  DOWNLOAD_COUNT,
} from "../actionTypes";

const initialState = {
  loader: false,
  servicesData: {},
  servicesPageData: {},
  singleServiceData: {},
};

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SERVICE_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case SET_SERVICES_DATA: {
      return {
        ...state,
        servicesData: action.payload,
      };
    }
    case SET_SERVICES_PAGE_DATA: {
      return {
        ...state,
        servicesPageData: action.payload,
      };
    }
    case SET_SINGLE_SERVICE_DATA: {
      return {
        ...state,
        singleServiceData: action.payload,
      };
    }
    case DOWNLOAD_COUNT: {
      return {
        ...state,
        servicesPageData: {
          ...state.servicesPageData,
          downloadCount: (state.servicesPageData?.downloadCount || 0) + 1,
        },
      };
    }
    default:
      return state;
  }
};

export default servicesReducer;
