import React from "react";
import { Helmet } from "react-helmet";
export default function MetaTags({ title, description, keywords, metaTitle }) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="metaTitle" content={metaTitle} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
    </Helmet>
  );
}
