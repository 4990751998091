import {
  MEDIA_CENTER_LOADER,
  SET_COURSES,
  SET_ELIBRARY,
  SET_INFO,
  SET_MEDIA_CENTER,
  SET_SEMINARS,
  SET_PHOTOS,
  SET_WORKSHOPS,
  SET_VIDEOS,
} from "../actionTypes";

const initialState = {
  loader: false,
  seminars: [],
  info: [],
  workshops: [],
  mediaCenter: {},
  eLibraries: [],
  courses: [],
  photos: [],
  videos: [],
};

const mediaCenterReducer = (state = initialState, action) => {
  switch (action.type) {
    case MEDIA_CENTER_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case SET_SEMINARS: {
      return {
        ...state,
        seminars: action.payload,
      };
    }
    case SET_INFO: {
      return {
        ...state,
        info: action.payload,
      };
    }
    case SET_MEDIA_CENTER: {
      return {
        ...state,
        mediaCenter: action.payload,
      };
    }
    case SET_WORKSHOPS: {
      return {
        ...state,
        workshops: action.payload,
      };
    }
    case SET_ELIBRARY: {
      return {
        ...state,
        eLibraries: action.payload,
      };
    }
    case SET_COURSES: {
      return {
        ...state,
        courses: action.payload,
      };
    }
    case SET_PHOTOS: {
      return {
        ...state,
        photos: action.payload,
      };
    }
    case SET_VIDEOS: {
      return {
        ...state,
        videos: action.payload,
      };
    }

    default:
      return state;
  }
};

export default mediaCenterReducer;
