import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import {
  JURY_PANEL_LOADER,
  SET_JURY_PANEL_DATA,
  DOWNLOAD_COUNT,
} from "../actionTypes";

export const getJuryPanelData = () => async (dispatch) => {
  try {
    dispatch({
      type: JURY_PANEL_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", "/jury-panel/get-list");
    if (res?.data) {
      dispatch({
        type: SET_JURY_PANEL_DATA,
        payload: res?.data?.data,
      });
    }
    dispatch({
      type: JURY_PANEL_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: JURY_PANEL_LOADER,
      payload: false,
    });
  }
};

export const updateDownloadCount = (id) => async (dispatch) => {
  try {
    dispatch({
      type: JURY_PANEL_LOADER,
      payload: true,
    });

    const res = await apiHelper("get", `/jury-panel/download/${id}`);
    if (res?.data) {
      dispatch({
        type: DOWNLOAD_COUNT,
      });
      dispatch({
        type: JURY_PANEL_LOADER,
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: JURY_PANEL_LOADER,
      payload: false,
    });
  }
};
