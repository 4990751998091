import React from "react";
import { Container } from "react-bootstrap";
import "./centralBuildingSec.css";

const CentralBuildingSec = ({ title, description, image }) => {
  return (
    <section className="central-building-sec text-center text-white position-relative">
      <Container className="custom-container">
        <h2 className="mb-3 text-white">{title}</h2>
        <p
          className="mb-4"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </Container>
    </section>
  );
};

export default CentralBuildingSec;
