import { FEEDBACK_LOADER, CREATE_FEEDBACK, ADD_FAVORITE } from "../actionTypes";

const initialState = {
  loader: false,
  feedback: {},
  favorite: {},
};

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case FEEDBACK_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case CREATE_FEEDBACK:
      return {
        ...state,
        feedback: action?.payload,
      };
    case ADD_FAVORITE:
      return {
        ...state,
        favorite: action?.payload,
      };
    default:
      return state;
  }
};

export default feedbackReducer;
