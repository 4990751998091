import {
  FETCH_NEWS_LIST,
  FETCH_SINGLE_NEWS,
  NEWS_LOADER,
} from "../actionTypes";

const initialState = {
  loader: false,
  newsList: [],
  singleNews: {},
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case NEWS_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case FETCH_NEWS_LIST: {
      return {
        ...state,
        newsList: action.payload,
      };
    }
    case FETCH_SINGLE_NEWS: {
      return {
        ...state,
        singleNews: action.payload,
      };
    }
    default:
      return state;
  }
};

export default newsReducer;
