import {
  SET_POLL_OPTION,
  FETCH_POLL_DATA,
  FETCH_LATEST_POLL_RESULT,
  POLL_LOADER,
} from "../actionTypes";

const initialState = {
  loader: false,
  pollData: {},
  pollResult: {},
  pollSubmitted: false,
};

const pollReducer = (state = initialState, action) => {
  switch (action.type) {
    case POLL_LOADER:
      return {
        ...state,
        loader: action?.payload,
      };
    case FETCH_POLL_DATA: {
      return {
        ...state,
        pollData: action.payload,
      };
    }
    case FETCH_LATEST_POLL_RESULT: {
      return {
        ...state,
        pollResult: action.payload,
      };
    }
    case SET_POLL_OPTION: {
      return {
        ...state,
        pollSubmitted: action.payload,
      };
    }
    default:
      return state;
  }
};

export default pollReducer;
