import axios from "axios";
import { config } from "../../config/config";
// import { toast } from 'react-toastify';

const baseUrl = config.serverUrl;
// let liveBaseUrl = ENV.liveServerUrl

async function apiHelper(apiType, path, data, token) {
  if (!baseUrl) {
    throw new Error("Base URL is not defined.");
  }

  if (
    apiType === "post" ||
    apiType === "put" ||
    apiType === "get" ||
    apiType === "delete" ||
    apiType === "patch"
  ) {
    const response = await axios({
      method: apiType,
      url: `${baseUrl + path}`,
      data,
      headers: {
        authorization: token,
        "ngrok-skip-browser-warning": "69420",
      },
    });
    return response;
  }
}

export { apiHelper };
