import React from "react";
import CountUp from "react-countup";
import { useDispatch } from "react-redux";
import { Toast } from "../../config/config";

export default function PageStats({
  countAction,
  downloadCount,
  viewCount,
  pdf,
  id,
}) {
  const dispatch = useDispatch();

  const handleDownloadPdf = () => {
    dispatch(countAction(id));
    const fileUrl = pdf;
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileUrl.split("/").pop();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => Toast.fire({ icon: "error", title: error }));
  };

  return (
    <div>
      <button onClick={handleDownloadPdf}>Download PDF</button>
      <p>
        This page was viewed <CountUp start={0} end={viewCount} duration={2} />{" "}
        times.
      </p>
      <p>
        This page was dowloaded{" "}
        <CountUp start={0} end={downloadCount} duration={2} /> times.
      </p>
    </div>
  );
}
