import { apiHelper } from "../../utils/apiHelper/apiHelper";
import { toast } from "react-toastify";
import { FEEDBACK_LOADER, CREATE_FEEDBACK, ADD_FAVORITE } from "../actionTypes";
import { helperFunction } from "../../utils/helperFunctions";
export const createFeedback = (paylaod) => async (dispatch) => {
  try {
    const token = JSON.parse(helperFunction.getToken());
    dispatch({
      type: FEEDBACK_LOADER,
      payload: true,
    });

    const res = await apiHelper("post", "/feedback/create", paylaod, token);
    if (res?.data) {
      dispatch({
        type: CREATE_FEEDBACK,
        payload: res.data?.data,
      });
      toast.success(res?.data?.message);
    }
    dispatch({
      type: FEEDBACK_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: FEEDBACK_LOADER,
      payload: false,
    });
  }
};

export const addToFavorite = (paylaod) => async (dispatch) => {
  try {
    const token = JSON.parse(helperFunction.getToken());
    dispatch({
      type: FEEDBACK_LOADER,
      payload: true,
    });

    const res = await apiHelper("post", "/favorite/create", paylaod, token);
    if (res?.data) {
      dispatch({
        type: ADD_FAVORITE,
        payload: res?.data?.data,
      });
      toast.success(res?.data?.message);
    }
    dispatch({
      type: FEEDBACK_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: FEEDBACK_LOADER,
      payload: false,
    });
  }
};
export const getFavorite = (paylaod) => async (dispatch) => {
  try {
    const token = JSON.parse(helperFunction.getToken());
    dispatch({
      type: FEEDBACK_LOADER,
      payload: true,
    });

    const res = await apiHelper("post", "/favorite/get", paylaod, token);
    if (res?.data) {
      dispatch({
        type: ADD_FAVORITE,
        payload: res?.data?.data,
      });
      toast.success(res?.data?.message);
    }
    dispatch({
      type: FEEDBACK_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: FEEDBACK_LOADER,
      payload: false,
    });
  }
};

export const reportLink = (paylaod) => async (dispatch) => {
  try {
    const token = JSON.parse(helperFunction.getToken());
    dispatch({
      type: FEEDBACK_LOADER,
      payload: true,
    });

    const res = await apiHelper("post", "/feedback/report", paylaod, token);
    if (res?.data) {
      toast.success(res?.data?.message);
    }
    dispatch({
      type: FEEDBACK_LOADER,
      payload: false,
    });
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: FEEDBACK_LOADER,
      payload: false,
    });
  }
};
